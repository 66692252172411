<template>
  <v-row
    :style="{
      height:
        $vuetify.breakpoint.width > '960'
          ? `calc(100vh - 260px)`
          : `calc(100vh - 130px)`,
    }"
  >
    <v-col v-if="$vuetify.breakpoint.width > '960'" align-self="center">
      <span class="text-h5">{{
        $t('MainAtendimientoChat.currentConversation')
      }}</span>
    </v-col>
    <v-col class="mb-1" style="padding: 0" cols="12">
      <v-banner
        style="height: 51px"
        color="primary"
        dark
        rounded
        single-line
        class="text-h6 d-flex"
      >
        <v-row>
          <v-col align-self="center" class="d-flex align-center">
            <v-btn
              @click="$emit('voltarMenuMobile', true)"
              icon
              v-if="$vuetify.breakpoint.width < '960'"
              class="mr-3"
            >
              <v-icon> mdi-menu-left </v-icon>
            </v-btn>
            <v-icon class="mr-4"> mdi-account </v-icon>
            <span v-if="nomeCliente != 'Conversa não iniciada'">
              {{ nomeCliente }}
              <v-btn icon @click="editName">
                <v-icon color="white"> mdi-pencil </v-icon>
              </v-btn>
              <v-dialog v-model="dialog_Name" persistent max-width="400px">
                <v-card>
                  <v-card-title> {{ $t('MainAtendimientoChat.EditName') }}</v-card-title>
                    <v-card-text>
                      <v-text-field v-model="editedName" :label="$t('MainAtendimientoChat.Name')"></v-text-field>
                      <v-text-field :disabled="true" v-model="client_Identify" :label="$t('MainAtendimientoChat.Phone')"></v-text-field>
                    </v-card-text>
                  <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDialogName">{{ $t('Guid.cancel') }}</v-btn>
                  <v-btn color="blue darken-1" text @click="updateClient">{{ $t('Guid.save') }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </span>
            <span v-else>
              {{ $t('MainAtendimientoChat.notStartedConversation') }}
            </span>
          </v-col>
          <v-col
            v-if="
              nomeCliente != 'Conversa não iniciada' &&
              $vuetify.breakpoint.width > '960'
            "
            class="d-flex justify-end"
          >
            <div v-if="Boolean(this.conversaDados.client_Id)">
              <v-btn
                @click="dialogHistorico = true"
                outlined
                class="pa-4 mr-2"
                small
              >
                {{ $t('MainAtendimientoChat.history') }}
              </v-btn>
            </div>
          </v-col>
          <v-col
            v-if="
              nomeCliente != 'Conversa não iniciada' &&
              $vuetify.breakpoint.width < '960'
            "
            class="d-flex justify-end"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text small fab>
                  <v-icon dark> mdi-dots-vertical </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-btn
                    @click="dialogHistorico = true"
                    class="pa-4 mr-2"
                    small
                    color="primary"
                  >
                    {{ $t('MainAtendimientoChat.history') }}
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-banner>
    </v-col>
    <v-col
      cols="12"
      style="
        display: flex;
        flex-direction: column-reverse;
        overflow: auto;
        border-radius: 8px;
        background-color: white;
      "
      :style="{ height: $vuetify.breakpoint.width < 960 ? '100%' : '100%' }"
      class="pa-3"
    >
      <ChatCliente
        :listaMsgAtendimento="listaMsgAtendimento"
        :emitNewMessageArray="emitNewMessageArray"
        :newMessageSended="newMessageSended"
        @clearMessagesSended="clearMessagesSended"
      ></ChatCliente>
    </v-col>
    <v-col class="mt-3 pa-0" cols="10">
      <div class="d-flex">
        <emoji-picker @emoji="append">
          <div
            slot="emoji-invoker"
            slot-scope="{ events: { click: clickEvent } }"
          >
            <button type="button">
              <v-icon
                size="32px"
                class="mt-5 mr-2"
                color="primary"
                :disabled="listaMsgAtendimento.length > 0 ? false : true"
                @click.stop="clickEvent"
              >
                mdi mdi-emoticon
              </v-icon>
            </button>
          </div>
          <div slot="emoji-picker" slot-scope="{ emojis, insert }">
            <div class="emoji-picker">
              <div>
                <div v-for="(emojiGroup, category) in emojis" :key="category">
                  <h5>{{ category }}</h5>
                  <div class="emojis">
                    <span
                      v-for="(emoji, emojiName) in emojiGroup"
                      :key="emojiName"
                      @click="insert(emoji)"
                      :title="emojiName"
                      >{{ emoji }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </emoji-picker>
        <v-textarea
          rows="2"
          :disabled="listaMsgAtendimento.length > 0 ? false : true"
          background-color="white"
          color="primary"
          v-model="message"
          append-outer-icon="mdi-send"
          filled
          clear-icon="mdi-close-circle"
          clearable
          :label="$t('MainAtendimientoChat.message')"
          @keyup.enter="sendMessage"
          @click:append-outer="sendMessage"
          @click:clear="clearMessage"
        >
        </v-textarea>
      </div>
    </v-col>
    <v-col class="mt-3 pa-0" cols="1">
      <template>
        <v-edit-dialog>
          <v-btn text large fab>
            <v-icon color="grey"> mdi-format-size </v-icon>
          </v-btn>

          <template v-slot:input>
            <div class="my-4 text-h6">
              {{ $t('MainAtendimientoChat.changeTemplate') }}
            </div>
            <v-autocomplete
              return-object
              @change="sendMessageButton"
              v-model="currentTemplate"
              :items="templates"
              item-text="message_Template_Name"
              item-value="message_Id"
              dense
              chips
              deletable-chips
              small-chips
              rounded
              solo
            >
            </v-autocomplete>
          </template>
        </v-edit-dialog>
      </template>
    </v-col>
    <v-col class="mt-3 pa-0" cols="1">
      <template>
        <v-edit-dialog>
          <v-btn text large fab>
            <v-icon color="grey"> mdi-paperclip </v-icon>
          </v-btn>
          <template v-slot:input>
            <div>
              <div class="my-4 text-h6">
                {{ $t('MainAtendimientoChat.changeImage') }}
              </div>
              <v-autocomplete
                return-object
                @change="sendImageButton"
                v-model="currentImage"
                :items="images"
                item-text="file_Name"
                item-value="file_Id"
                dense
                deletable-chips
                small-chips
                rounded
                solo
              >
              </v-autocomplete>
            </div>
            <div>
              <v-btn @click="onButtonClick" class="ma-8" color="primary"
                >{{ $t('Guid.import') }}
                <v-icon right dark> mdi-cloud-upload </v-icon>
              </v-btn>
              <input
                @change="onFileChanged"
                ref="fileUpload"
                class="d-none"
                type="file"
              />
            </div>
          </template>
        </v-edit-dialog>
      </template>
    </v-col>
    <HistoricoClienteVue
      v-if="dialogHistorico"
      @closeDialog="dialogHistorico = $event"
      :dialog="dialogHistorico"
      :clientId="conversaDados.client_Id"
    ></HistoricoClienteVue>
    <v-snackbar v-model="snack" timeout="3000">
      {{ $t(snackText) }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snack = false">
          {{ $t('Guid.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import HistoricoClienteVue from './HistoricoCliente.vue';
import ChatCliente from './ChatCliente.vue';
import { mapGetters } from 'vuex';
import { CONNECTIONSIGNALR } from '../plugins/signalR';
import axios from 'axios';
import EmojiPicker from 'vue-emoji-picker';

export default {
  components: {
    HistoricoClienteVue,
    ChatCliente,
    EmojiPicker,
  },
  props: {
    conversaDados: {
      required: true,
    },
    messageAuto: {
      required: true,
    },
    telephone: {
      required: true,
    },
    notifyMessage: {
      required: true,
    },
    newMessagesArray: {
      required: true,
    },
    goToMessages: {
      required: true,
    },
    errorMessage: {
      required: true,
    },
  },
  data() {
    return {
      att_mes: process.env.VUE_APP_ENV_API_SERVICES_IMAGE,
      att_image: process.env.VUE_APP_ENV_API_IMAGES,

      exc_int: axios.create({
        baseURL: process.env.VUE_APP_ENV_HORNITOS_INT,
      }),

      listaMsgAtendimento: [],
      currentTemplate: {},
      currentImage: {},
      templates: [],
      images: [],
      client: localStorage.getItem('customer_Id'),

      token: JSON.parse(localStorage.getItem('authUser')).token,
      user: JSON.parse(localStorage.getItem('authUser')).user_Name,
      startAtt: JSON.parse(localStorage.getItem('authUser')).start_Att,
      endAtt: JSON.parse(localStorage.getItem('authUser')).end_Att,

      convAtual: JSON.parse(localStorage.getItem('convAtual')),

      password: 'Password',
      show: false,
      message: '',
      image: '',
      marker: true,
      iconIndex: 0,
      dialog_Name: false,
      editedName: '',
      client_Identify: '',
      originalName:'',

      // tamanhoTelaAtual: innerHeight - 70.42 - 56 - 57 - 86,

      enviarMsg: false,

      interval: null,

      connection: null,

      dialogHistorico: false,

      snack: false,
      snackColor: '',
      snackText: '',

      fileName: '',
      sendingMessage: false,

      newMessageSended: '',
      emitNewMessageArray: [],
    };
  },
  methods: {
    editName() {
      this.dialog_Name = true;
    },
    closeDialogName() {
      this.dialog_Name = false;
    },
    append(emoji) {
      this.message += emoji;
    },
    sendMessageButton() {
      this.message = this.currentTemplate.message_Template;
      this.message = this.message.replace(
        '{{Agent_Name}}',
        this.$store.state.permissions.user_Name
      );
      this.currentTemplate = {};
    },
    sendImageButton() {
      this.message = this.message + this.att_mes + this.currentImage.file_Name;
    },
    updateClient() {
      this.$emit('updateClient', this.editedName);
      this.dialog_Name = false;
    },
    templateList() {
      this.$http
        .get(`Message_Template_User_List?Customer_Id=${this.client}`, {
          headers: {
            accept: '*/*',
            Authorization: `bearer ${this.token}`,
          },
        })
        .then((r) => {
          this.templates = r.data;
          let mss = this.templates.find(
            (elem) => elem.message_Template_Name === 'auto-asignacion'
          );
          this.$emit('messageWelcome', mss.message_Template);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('setDisconnectedByNetworkError', true);
            this.logout();
          }
        })
        .finally(() => {
          //this.loading = false
        });
    },
    imageList() {
      this.$http
        .get(`Att_File_List?Customer_Id=${this.client}`, {
          headers: {
            accept: '*/*',
            Authorization: `bearer ${this.token}`,
          },
        })
        .then((r) => {
          this.images = r.data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('setDisconnectedByNetworkError', true);
            this.logout();
          }
        })
        .finally(() => {
          //this.loading = false
        });
    },
    async listar() {
      await this.$http
        .get(
          `User_Att_Messages_List?Customer_Id=${localStorage.getItem(
            'customer_Id'
          )}&Attendence_Id=${
            this.conversaDados.attendence_Id
              ? this.conversaDados.attendence_Id
              : this.idConversa
          }`,
          {
            headers: {
              accept: '*/*',
              Authorization: `bearer ${this.token}`,
            },
          }
        )
        .then((r) => {
          this.listaMsgAtendimento = r.data;
          for (const msg of this.listaMsgAtendimento) {
            if (msg.message_Direction == 0) {
              if (
                msg.message.slice(-5) == '.jpeg' ||
                msg.message.slice(-4) == '.pdf' ||
                msg.message.slice(-4) == '.mp4' ||
                msg.message.slice(-4) == '.mp3' ||
                msg.message.slice(-4) == '.doc'
              ) {
                msg.message = this.att_image + msg.message;
              }
            }
          }
        })
        .catch((e) => {
          if (e.response.status == 401) {
            this.$store.commit('setDisconnectedByNetworkError', true);
            this.logout();
          }
        })
        .finally(() => {
          //this.loading = false
        });
    },
    async logout() {
      await CONNECTIONSIGNALR.stop();
      localStorage.removeItem('authUser');
      localStorage.removeItem('convAtual');
      this.$store.commit('setInitialData');
      this.$router.push({ name: 'home' });
    },
    sendMessage() {
      this.$emit('logOutUnactive');
      if (!this.sendingMessage) {
        this.sendingMessage = true;
        let urlImage = '';
        if (this.currentImage.file_Name) {
          urlImage = this.att_mes + this.currentImage.file_Name;
        }
        if (this.message != '' && this.message.trim().length != 0) {
          this.$http
            .post(
              `User_Att_Messages_Send?Customer_Id=${localStorage.getItem(
                'customer_Id'
              )}&Attendence_Id=${
                this.conversaDados.attendence_Id
                  ? this.conversaDados.attendence_Id
                  : this.idConversa
              }&Message=${this.message}&Image_Name=${urlImage}`,
              {},
              {
                headers: {
                  Authorization: `bearer ${this.token}`,
                },
              }
            )
            .then(() => {
              this.sendingMessage = false;
              // this.listar();
              this.newMessageSended = this.message;
              this.enviarMsg = !this.enviarMsg;
              this.$emit('enviarMensagem', this.enviarMsg);
              if (this.conversaDados.client_Id === 0) {
                this.$emit('historicButton', this.conversaDados);
              }
              this.clearMessage();
              this.$emit('reloadServices', true);
            })
            .catch((error) => {
              if (error.response.status == 401) {
                this.$store.commit('setDisconnectedByNetworkError', true);
                this.logout();
              } else {
                console.log('Error al enviar el mensaje');
                console.log(error);
                this.snack = true;
                this.snackColor = 'error';
                this.snackText = 'MainAtendimientoChat.clienteInService';
                this.enviarMsg = !this.enviarMsg;
                this.$emit('InServices');
                this.message = '';
                this.sendingMessage = false;
                this.clearMessage();
                this.$emit('errorMessage', true);
                this.$emit('reloadServices', false);
              }
            });
        } else {
          this.sendingMessage = false;
        }
      }
    },
    clearMessage() {
      this.$emit('messageAuto', '');
      this.message = '';
      this.currentImage = {};
    },
    onButtonClick() {
      window.addEventListener('focus', () => {}, {
        once: true,
      });
      this.$refs.fileUpload.click();
    },
    onFileChanged(e) {
      this.fileName = e.target.files[0].name.split(' ').join('_');
      this.selectedFile = e.target.files[0];
      let stringInFile = new FileReader();
      let base64Img = [];
      let imgData = [];

      stringInFile.onloadend = () => {
        base64Img = stringInFile.result.split(',');
        imgData = this.fileName.split('.');

        let fileName = imgData.slice(0, -1).join('.');

        try {

          let object = {
            file: base64Img[1],
            format: imgData[imgData.length - 1],
            file_name: fileName.split(' ').join('_'),
          };
          
          let config = {
            headers: {
              'Content-Type': 'application/json',
            },
          };

          this.exc_int
            .post('hornitos/', object, config)
            .then(() => {
              this.$refs.fileUpload.value = null;
              this.message =
                this.message +
                ' ' +
                process.env.VUE_APP_ENV_HORNITOS_IMG +
                '/' +
                this.fileName;
            })
            .catch(() => {
              this.snack = true;
              this.snackColor = 'success';
              this.snackText = 'MainAtendimientoChat.ErrorWhenStoragedFile';
            });
        } catch (error) {
          this.snack = true;
          this.snackColor = 'success';
          this.snackText = 'MainAtendimientoChat.ErrorProcessFile';
        }
      };

      stringInFile.readAsDataURL(this.selectedFile);
    },
    clearMessagesSended() {
      this.newMessageSended = null;
    },
  },
  computed: {
    ...mapGetters(['getUserInformation']),
    ...mapGetters(['getCurrentCustomer']),
    ...mapGetters(['getListDataInformation']),
    icon() {
      return this.icons[this.iconIndex];
    },
    idConversa() {
      if (this.convAtual) {
        return JSON.parse(localStorage.getItem('convAtual')).attendence_Id;
      }
      return 0;
    },
    nomeCliente() {
      if (this.conversaDados.client_Name) {
        return this.conversaDados.client_Name;
      } else {
        return this.convAtual
          ? this.convAtual.client_Name
          : 'Conversa não iniciada';
      }
    },
  },

  watch: {
    getCurrentCustomer() {
      this.client = this.getCurrentCustomer.customer_Id;
      this.templateList();
      this.imageList();
      this.listar();
    },
    conversaDados(newData) {
      this.listar();
      this.editedName = newData.client_Name;
      this.client_Identify = newData.client_Identify;
    },
    message() {
      this.$emit('messageChat', this.message);
    },
    messageAuto() {
      this.message = this.messageAuto;
      this.sendMessage();
    },
    async telephone() {
      if (
        this.telephone === null ||
        this.telephone !== this.conversaDados.client_Identify
      ) {
        this.$emit('notifyMessageInMyChats', this.telephone);
      }
    },
    async goToMessages() {
      if (this.goToMessages) {
        await this.listar();
        this.$emit('listMessages', false);
      }
    },
    async newMessagesArray() {
      if (
        this.telephone !== null &&
        this.telephone === this.conversaDados.client_Identify
      ) {
        // await this.listar()
        if (this.newMessagesArray.length > 0) {
          this.emitNewMessageArray = this.newMessagesArray;
        }
        this.$emit('updateMessage');
      }
    },
  },
  created() {},
  async mounted() {
    this.listar();
    this.templateList();
    this.imageList();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style>
.wrapper {
  position: relative;
  display: inline-block;
}

.regular-input {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 20rem;
  height: 12rem;
  outline: none;
}

.regular-input:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.emoji-invoker {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  padding: 0;
  background: transparent;
  border: 0;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}
.emoji-invoker > svg {
  fill: #b1c6d0;
}

.emoji-picker {
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  border: 1px solid #ccc;
  width: 15rem;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
  /* Cambia la propiedad top por bottom */
  bottom: 30px; /* Ajusta este valor según sea necesario */
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: '';
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}
</style>
