<template>
  <div>
    <div v-for="(msg, i) in messages" :key="i">
      <div class="d-flex" :class="ladoMsg(msg.message_Direction).justify">
        <v-alert
          :color="ladoMsg(msg.message_Direction).color"
          dark
          dense
          style="max-width: 65%; word-break: break-word"
          :style="
            msg.message_Direction == 0
              ? [{ borderRadius: '15px', borderTopLeftRadius: '0' }]
              : [{ borderRadius: '15px', borderTopRightRadius: '0' }]
          "
        >
          <div v-if="msg.message.includes('.jpeg') || msg.message.includes('.jpg')  || msg.message.includes('png') ">
            <v-img 
            :src="msg.message"
            max-height="400"
            max-width="300"
            cover
            @click="dialogImageMethod(msg.message)"></v-img>
            <v-dialog v-if="dialogImage"
              v-model="dialogImage"
              width="auto">
            <template v-slot:activator="{ props }">
              <v-btn v-show="false"
                color="primary"
                v-bind="props"
              />
            </template>
              <v-card>
                <v-img 
                  :src="dialogURL"
                  height="650px"
                  contain></v-img>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    variant="text"
                    @click="dialogImage = false"
                  >
                    {{ $t("Guid.ok") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div v-else-if="msg.message.slice(-3)=='mp3' || msg.message.slice(-3)=='ogg'">
            <audio controls :src="msg.message" type="audio/mpeg"></audio>
          </div>
          <div v-else-if="msg.message.slice(-3)=='mp4'">
            <iframe width="320" height="320" :src="msg.message" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
          </div>
          <div v-else>
            {{ msg.message }}
          </div>
          <v-chip
            small
            color="#ffffff1f"
            style="float: right; font-size: 13px"
            class="ml-16"
          >
            {{ getLocalDate(msg.message_Update) }}
          </v-chip>
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listaMsgAtendimento: {
      default: null,
    },
    emitNewMessageArray: {
      required: false,
    },
    newMessageSended: {
      required: false,
    }
  },
  data() {
    return {
      messages: this.listaMsgAtendimento,
      att_image: process.env.VUE_APP_ENV_API_IMAGES,
      dialogImage: false,
      dialogURL: "",
    }
  },
  methods: {
    dialogImageMethod(url){
      this.dialogURL=url
      this.dialogImage=true
    },
    ladoMsg(direcao) {
      if (direcao == 0 || direcao == 3) {
        return { justify: "justify-end", color: "primary" };
      }
      if (direcao == 2 || direcao == 1) {
        return { justify: "justify-start", color: "secondary" };     
      }
    },
    getLocalDate(start_Queue) {
      let newDate = new Date(start_Queue + 'Z');

      let formatDate = 
        [
          newDate.getFullYear(),
          newDate.getMonth()+1,
          newDate.getDate()
        ].join('-') +' ' +
        [newDate.getHours(),
          String(newDate.getMinutes()).padStart(2, '0'),
          String(newDate.getSeconds()).padStart(2, '0')].join(':').toString();

      return formatDate;
    }
  },
  watch: {
    async listaMsgAtendimento() {
      this.messages = this.listaMsgAtendimento
    },
    async emitNewMessageArray() {
      let msg = "";
      for (let i=0; i < this.emitNewMessageArray.length; i++) {

        if (this.emitNewMessageArray[i].message.slice(-5) == ".jpeg" || 
          this.emitNewMessageArray[i].message.slice(-4) == ".pdf" ||
          this.emitNewMessageArray[i].message.slice(-4) == ".mp4" ||
          this.emitNewMessageArray[i].message.slice(-4) == ".mp3" ||
          this.emitNewMessageArray[i].message.slice(-4) == ".doc") {
          msg = this.att_image + this.emitNewMessageArray[i].message
        } else {
          msg = this.emitNewMessageArray[i].message
        }

        this.messages.push({ 
          "attendence_Id": this.emitNewMessageArray[i].attendence_id,
          "message": msg,
          "message_Direction": this.emitNewMessageArray[i].message_direction,
          "message_Id": 0,
          "message_Update": this.emitNewMessageArray[i].message_update
        });
      }
    },
    async newMessageSended() {
      let data = new Date();
      var dataBase = data.toISOString().replace(/\.\d{3}Z$/, '');
      if(this.newMessageSended != "" && this.newMessageSended != null) {
        this.messages.push({
          "attendence_Id": 0,
            "message": this.newMessageSended,
            "message_Direction": 1,
            "message_Id": 0,
            "message_Update": dataBase
        })
        this.$emit("clearMessagesSended")
      }
    }
  }
};
</script>

<style>
</style>